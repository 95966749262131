import { jsToCallApp } from './callApp1.0.0'

export function openAppFunction(type, jsonData) {
    const useTimeOut = setTimeout(() => {
      jsToCallApp(
        {
          type: type,
          ...jsonData,
        },
        (data) => {
          console.log(type, data)
        },
      )
      clearTimeout(useTimeOut);
    }, 300);
  }