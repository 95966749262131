<template>
  <div class="footer">
    <!-- <div class="item">
      <router-link to="/" class="nav_item" active-class="nav_color">
        <img :src="$route.path == '/'?require('@/assets/images/footer/tab1_a.png'):require('@/assets/images/footer/tab1.png')" alt="" >
        职工之家
      </router-link>
    </div> -->
    <div class="item">
      <router-link to="/foundation" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/foundation' || $route.path === '/' ? require('@/assets/images/footer/tab2_a.png') : require('@/assets/images/footer/tab2.png')"
          alt="">
        爱心互献
      </router-link>
    </div>
    
    <div class="item">
      <router-link to="/donate/donateList" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path.includes('/donate') ? require('@/assets/images/footer/tab3_a.png') : require('@/assets/images/footer/tab3.png')"
          alt="">
        爱心捐赠
      </router-link>
    </div>
    <div class="item">
      <router-link to="/materialsApply" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/materialsApply' ? require('@/assets/images/footer/tab4_a.png') : require('@/assets/images/footer/tab4.png')"
          alt="">
        物资申领
      </router-link>
    </div>
<!--   <div class="item">
      <router-link to="/find" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/find' ? require('@/assets/images/footer/tab_find_a.png') : require('@/assets/images/footer/tab_find.png')"
          alt="">
        捐赠查询
      </router-link>
    </div> -->
    <!--  <div class="item">
      <router-link to="/wish" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/wish' ? require('@/assets/images/footer/tab_wish_a.png') : require('@/assets/images/footer/tab_wish.png')"
          alt="">
          微心愿
      </router-link>
    </div>-->
    <div class="item">
      <router-link to="/aboutMe" class="nav_item" active-class="nav_color">
        <img
          :src="$route.path == '/aboutMe' ? require('@/assets/images/footer/tab1_a.png') : require('@/assets/images/footer/tab1.png')"
          alt="">
        关于我们
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
.footer {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  background-color: #fff;
  border-top: 0.5px solid #eee;
  height: 2.2667rem;
  padding-top: 12px;

  .item {
    display: flex;
    justify-content: center;

    img {
      width: .6133rem;
      height: .6133rem;
      margin-bottom: 5px;
    }

    .nav_item {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
}
</style>