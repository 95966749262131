<template>
  <div class="find">
    <div class="content" style="background-color: #fff;" >
      <!-- 顶部 -->
      <Header :titleName="data.titleName" :return="true" :borderShow="true" />

      <div class="white_mian">
        <!-- 捐赠项目类目tab -->
        <div class="project_tab">
          <div :class="data.tab == 1 ? 'active' : ''" @click="tabChange(1)">
            证书查询
            <div class="line"></div>
          </div>
          <div :class="data.tab == 2 ? 'active' : ''" @click="tabChange(2)">
            申领查询
            <div class="line"></div>
          </div>
        </div>


        <div class=" search_input space_between align_items_center">
          <div class="align_items_center">
            <van-dropdown-menu>
              <van-dropdown-item v-model="data.postData.filterType"
                :options="data.tab == 1 ? data.option1 : data.option2" @change="filterTypeChange" />
            </van-dropdown-menu>
            <van-field v-model="data.postData.name" v-if="data.tab == 1"
              :placeholder="'请输入' + data.option1[data.postData.filterType - 1].text" />
            <van-field v-model="data.postData.name" v-if="data.tab == 2"
              :placeholder="'请输入' + data.option2[data.postData.filterType - 1].text" />
          </div>
          <div class="serach_btn" @click="serachBtn()">查询</div>
        </div>


        <!-- 领取列表 -->
        <div class="list" v-if="data.tab == 2">
          <div class="flex" v-for="(item, index) in data.applyList" :key="index" :class="index == 2 ? 'active' : ''">
            <img :src="item.imgUrl" alt="">
            <div class="lir" style="width: 6rem;">
              <div>{{ item.applyForProjectName }}</div>
              <div class="time">申领日期：{{ item.newsTime }}</div>
              <div class="slbtn" @click="toHXMdetail(item.id)"> 查看</div>
            </div>
          </div>
        </div>
      </div>

      <div class="allpages" v-if="data.zsTotal>0"> 共计{{data.zsTotal}}个证书 </div>
      <div class="search_mian" v-infinite-scroll="loadMore" infinite-scroll-distance="0">
        <!-- 列表为空 -->
        <div class="find_list_empty" v-if="data.zsList.length < 1 && !data.loading && !data.applyList.length&&data.emptyShow">
          <img src="@/assets/images/find/find_list_empty.png" alt="">
          <div class="first_div"></div>
          <div class="last_div"></div>
        </div>


        <van-loading color="#888888" v-if="data.loading" vertical style="margin-top: 20vh;">加载中...</van-loading>
        
        <!-- 证书列表 -->
        <div class="zs_list " v-if="data.tab == 1">
          
          <div v-for="(item, index) in data.zsList" :key="index" @click="toZSdeatil(item)">
            <span class="tag">{{  index < 9 ? 0 + '' + (index + 1) : index + 1}}</span>
            <div class="space_between fw6" style=" margin-bottom: .1333rem;">
              <div style="font-size: .4rem;margin-right: .3rem;">{{ item.tmpName = item.type == "2" ? ((item.briefl_name != '' && item.briefl_name != null) ? item.briefl_name : "爱心人士") : ((item.person ==''||item.person ==null)?'爱心人士':item.person) }}</div>
              <div style="font-size: .5067rem;white-space: nowrap;">{{item.amount}}</div>
            </div>
            <div class="space_between" style=" margin-bottom: .1333rem;">
              <div style="font-size: .2933rem;color: #ACACAC;">{{ item.newPayTime}}捐赠</div>
              <div style="font-size: .2933rem;color: #ACACAC;">捐赠金额(元)</div>
            </div>
            <div class="space_between">
              <div></div>
              <div style="color: #D80010;" class="align_items_center">
                <img style="width: .3067rem;margin-right: .1rem;" src="@/assets/images/find/toright.png" alt="">
                查看
              </div>
            </div>
          </div>


        </div>
        <!-- <div v-if="data.noMore" style="text-align: center;font-size: .3867rem;color: #888;">暂无更多~</div> -->
      </div>
    </div>

   <Footer />
  </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router'
import Header from "../../components/Header.vue";
import { Toast, Popup } from 'vant';
import Api from "../../utils/api";
import axios from "axios";
import html2canvas from 'html2canvas'
import { ImagePreview } from 'vant';
import { parse } from "@vue/compiler-sfc";
const route = useRoute()
const router = useRouter()
import { openAppFunction } from '../../common/js/share'

let data = reactive({
  loading: false,
  option1: [
    { text: '姓名', value: 1 },
    { text: '公司名称', value: 2 },
    { text: '手机号', value: 3 },
    { text: '订单号', value: 4 },
  ],
  option2: [
    { text: '手机号', value: 1 },
    { text: '身份证', value: 2 },
  ],
  capNameSize: 18,
  tab: 1,
  postData: {
    filterType: 1,
    name: '',
    limit: 10,
    page: 1,
  },
  noMore: false,
  zsList: [],
  applyList: [],
  zsTotal:0,
  emptyShow:false,
  titleName:'查询',
});



onMounted(() => {
  console.log(router) 
  // 判断上一页是不是详情 是的话列表加载出来
  if (sessionStorage.getItem('searchKeyword')) {
    if (router.options.history.state.forward) {

      if (router.options.history.state.forward.includes('/findApplyMsg')||router.options.history.state.forward.includes('/certificate')) {
        let quData = JSON.parse(sessionStorage.getItem('searchKeyword'))
        data.tab = quData.tab
        data.postData.name = quData.postData.postName
        data.postData.filterType = quData.postData.filterType
        console.log(quData)
        if (data.tab == 1) {
          getwelFareSeek()
        }
        if (data.tab == 2) {
          getApplyList()
        }
      }
    }
    sessionStorage.removeItem('searchKeyword')
  }


  shareFn()

});

const shareFn = () => {
  const jsonString = {
    shareUrl:window.location.href,//分享链接
    shareCorver: window.location.origin + '/logo.png',
    shareTitle: '中国职工发展基金会',
    shareDesc: data.titleName,//分享描述
  }
  console.log(jsonString)
  openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
}


//查看领取详情
const toHXMdetail = (id) => {
  sessionStorage.setItem('searchKeyword', JSON.stringify({ tab: 2, postData: { filterType: data.postData.filterType, postName: data.postData.name } }))
  router.push({ path: '/findApplyMsg', query: { id, } })
};
//查看证书详情
const toZSdeatil = (item) => {
  sessionStorage.setItem('searchKeyword', JSON.stringify({ tab: 1, postData: { filterType: data.postData.filterType, postName: data.postData.name } }))
  const tmpName = item.type == "2" ? ((item.briefl_name != '' && item.briefl_name != null) ? item.briefl_name : "爱心人士") : ((item.person ==''||item.person ==null)?'爱心人士':item.person) 
  router.push({ path: '/certificate', query: { tmpName: tmpName, amount: item.amount, pay_time: item.pay_time, id: item.id } })
};
//获取申领列表
const getApplyList = () => {

  let postData = {}
  if (!data.postData.name) {
    let msg = data.postData.filterType == 1 ? '手机号' : '身份证号'
    Toast('请输入' + msg)
    return
  }

  if (data.postData.filterType == 1) {
    // 手机号确认
    let phoneReg = /^1[3456789]\d{9}$/;
    if (!phoneReg.test(data.postData.name)) {
      Toast('请输入正确手机号！');
      return
    }
  }


  if (data.postData.filterType == 2) {
    //  身份证确认
    let regExp = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!regExp.test(data.postData.name)) {
      Toast('请输入正确身份证号！');
      return
    }
  }

  postData.PageIndex = 1
  postData.PageSize = 100




  if (data.postData.filterType == 1) {
    postData.Mobile = data.postData.name
  } else {
    postData.IdNumber = data.postData.name
  }


  data.loading = true
  Api.applyUserList(postData).then(res => {
    if (res.status == 200) {
      data.applyList = res.data
      for (let i = 0; i < data.applyList.length; i++) {
        data.applyList[i].newsTime = getTime(data.applyList[i].addTime)
      }
      data.loading = false
    }
    console.log(res)
  })
}

const filterTypeChange = () => {
  data.postData.name = ''
};

//获取时间
const getTime = (time) => {
  // 时间戳 
  let timestamp = new Date(time).getTime()
  console.log(timestamp)
  // 此处时间戳以毫秒为单位
  let date = new Date(parseInt(timestamp));
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  return Year + '-' + Moth + '-' + Day
};

//证书时间转化
const filterTime = (time) => {
  // 时间戳 
  // let timestamp = new Date(time).getTime()
  // console.log(timestamp)
  // 此处时间戳以毫秒为单位
  let date = new Date(parseInt(time) * 1000);
  let Year = date.getFullYear();
  let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
  let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  return Year+ '年' +Moth + '月' + Day + '日'
};

//获取捐赠证书列表
const getwelFareSeek = () => {
  data.zsTotal = 0
  let postData = {}
  let postDataName = ['filterType', 'name', 'limit', 'page']


  for (let i = 0; i < postDataName.length; i++) {
    postData[postDataName[i]] = data.postData[postDataName[i]]
  }
  if (!postData.name) {
    let msg = data.option1[postData.filterType-1].text
    // postData.filterType == 1 ? '姓名' : '手机号'
    Toast('请输入' + msg)
    return
  }
  if (postData.filterType == 3) {
    // 手机号确认
    let phoneReg = /^1[3456789]\d{9}$/;
    if (!phoneReg.test(postData.name)) {
      Toast('请输入正确手机号！');
      return
    }
  }
  data.loading = true
  axios({
    method: "get",
    url: 'https://servers.china-wdf.org.cn/api/search/welfare/seek',
    params: postData,
  })
    .then((res) => {

      if (res.data.code == 1) {

        if (postData.page > 1) {
          data.zsList.push(...res.data.data.data)
          // data.zsList = [data.zsList,...res.data.data.data]
        } else {
          data.zsList = res.data.data.data
        }

        for (let i = 0; i < data.zsList.length; i++) {
          data.zsList[i].tmpName = data.zsList[i].briefl_name != '' ? data.zsList[i].briefl_name : (data.zsList[i].person == '' ? '爱心人士' : data.zsList[i].person)
          data.zsList[i].newPayTime = filterTime(data.zsList[i].pay_time)
          data.zsTotal = res.data.data.total
        }

      } else {
        data.zsList = []
      }

      data.loading = false
    })
};



//输入框点击查找
const serachBtn = () => {
  data.postData.page = 1
  data.emptyShow = true

  if (data.tab == 1) {
    data.zsList = []
    getwelFareSeek()
  }
  if (data.tab == 2) {
    // data.zsList = []
    getApplyList()
  }
}

//类目切换
const tabChange = (i) => {
  data.tab = i
  data.postData.filterType = 1
  data.postData.name = ''
  data.zsList = []
  data.applyList = []
  data.zsTotal = 0
  data.emptyShow = false
}


//触底事件
const loadMore = () => {
  if (data.zsList.length > 0) {
    console.log('9999999999999999999999')
    if (data.zsList.length % 10 == 0) {
      data.postData.page++
      getwelFareSeek()
      data.noMore = false
    } else {
      data.noMore = true
    }

  }

}



</script>

<style lang='less' scoped>
/deep/ .van-tabs__wrap {
  border-radius: 10px;
}

::v-deep input.van-field__control::-webkit-input-placeholder {
  color: #ACACAC;
  font-size: .3867rem;
}



.find {
  display: flex;
  flex-flow: column;
  height: 100%;
  font-size: 12px;
  
  .certificate {
    width: 100%;
  }

  .allpages{
    width: 100%;
    height: 1.1333rem;
    line-height: 1.1333rem;
    background: #FFF4F3;
    border-radius: 0px 0px 0px 0px;
    color: #E61D18;
    font-weight: 600;
    font-size: .4rem;
    text-align: center;
  }
  .zs_list {
    margin-top: .2667rem;
    position: relative;
    >div {
      padding: .48rem;
      padding-bottom: .2667rem;
      background: #FFFFFF;
      border-radius: .1067rem;
      border: 1px solid #ECECEC;
      margin-bottom: .2667rem;
      position: relative;
      .tag{
        background-color: #D80010;
        width: .92rem;
        height: .4267rem;
        border-radius: .1067rem 0px .1067rem 0px;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .2667rem;
      }
    }
  }

  

  .white_mian {
    background-color: #fff;
    padding: .5067rem;
    padding-bottom: .1rem;
  }

  .list {
    >div {
      height: 2.96rem;
      font-size: .3867rem;
      border-bottom: .0133rem #ececec solid;
      padding-top: .2667rem;
      position: relative;

      &:last-child {
        border: none;
      }

      .lir {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 2.1733rem;
      }

      .white-space1 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .slbtn {
        width: 1.5333rem;
        height: .72rem;
        background-color: #E61D18;
        position: absolute;
        right: 0;
        bottom: .5067rem;
        border-radius: .0533rem;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }


      .time {
        color: #ACACAC;
        font-size: .3067rem;
      }

      img {
        width: 2.8267rem;
        height: 2.1733rem;
        border-radius: .1067rem;
        margin-right: .3333rem;
      }
    }

  }


  .find_list_empty {
    text-align: center;
    padding-top: 10vh;

    img {
      width: 5.92rem;
      height: 3.1867rem;
    }

    .first_div {
      font-size: .4667rem;
      font-weight: 500;
      margin-top: .6667rem;
    }

    .last_div {
      font-size: .36rem;
      color: #ACACAC;
      margin-top: .2rem;

    }
  }

  .search_input {
    border: .0133rem solid #ACACAC;
    height: .92rem;
    position: relative;
    border-radius: .1333rem;
    margin-bottom: .2667rem;

    .van-dropdown-menu {
      width: auto;
      margin-right: .5rem;
      box-shadow: none;

      background-color: rgba(0, 0, 0, 0);

      /deep/.van-dropdown-menu__bar {
        height: .8667rem;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);
        height: 1rem;

        .van-dropdown-menu__item {
          justify-content: flex-start;
          background-color: rgba(0, 0, 0, 0);
          box-shadow: none;

        }

        .van-dropdown-menu__title {
          font-size: .36rem;

          &::after {
            border-color: transparent transparent #989898 #989898;
          }
        }
      }

    }

    .van-cell {
      padding: 0;
      border: none;
    }

    .serach_btn {
      position: absolute;
      right: -0.0133rem;
      top: -.0133rem;
      width: 1.5333rem;
      height: .93rem;
      background: #E61D18;
      border-radius: 0px .1333rem .1333rem 0px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .36rem;
    }
  }

  .search_mian {
    padding: 0 .5067rem;
    // background-color: red;
    height: calc(100vh - 5.68rem);
    overflow: auto;
  }

  .content {
    flex: 1;
    overflow-y: auto;

    .project_tab {
      font-size: .36rem;
      display: flex;
      align-items: center;
      margin-bottom: .3067rem;
      background-color: #fff;

      >div {
        margin-right: .5067rem;
        color: #777777;
      }

      .line {
        margin-top: .1067rem;
        height: .1067rem;
        border-radius: 1.28rem;
      }

      .active {
        color: #333;
        font-size: .44rem;
        font-weight: 600;

        .line {
          width: 90%;
          background: linear-gradient(240deg, rgba(255, 255, 255, 0) 0%, #E61D18 100%);
        }
      }
    }




  }
}
</style>